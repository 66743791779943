.projectsContainer {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }
  
  .projectsContainer h3 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .projectsContainer p {
    font-size: 20px;
    line-height: 1.5;
    max-width: 600px;
    margin-bottom: 40px;
  }

.card-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

}

@media screen and (min-width: 1400px){
  .projectsContainer h3 {
    font-size: 42px;
    margin-bottom: 60px;
  }
}