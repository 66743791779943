.aboutWrapper{
  overflow: hidden;
}

.aboutContainer {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding: 100px;

  }
  
  .aboutContainer h3 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .aboutContainer p {
    font-size: 20px;
    line-height: 1.5;
    max-width: 600px;
    margin-bottom: 40px;
  }
  
  @media screen and (min-width: 1400px){
    .aboutContainer{
      margin-top: 200px;
    }

    .aboutContainer h3{
      font-size: 48px;
    }

    .aboutContainer p{
      font-size: 26px;
    }
}