.pageWrapper {
    display: flex;
    height: 100%;
  }
  
  .mainInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    width: 50%;
    padding: 110px;
  }
  
  .mainInfoContainer {
    margin-bottom: 0.5vh;
  }
  
  .mainRoutes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 150px;
  }
  
  .mainRoutes a {
    text-decoration: none;
    text-align: center;
    color: #1a1818;
    text-transform: uppercase;
    font-size: 11vw;
    font-weight: 300;
    line-height: 0.95em;
    margin-bottom: 0.5vh;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out, font-size 0.3s ease-in-out;
  }
  
  .mainRoutes a:hover {
    transform: scale(1.1);
    color: #252930;
  }

/* ============ Existing Large-Desktop Breakpoint ============ */
@media screen and (min-width: 1400px) {
  .mainRoutes {
    padding: 300px;
  }
  .mainInfo {
    padding: 300px;
    font-size: 18px;
  }
}

/* ============ Existing Smaller Devices Breakpoint ============ */
@media screen and (max-width: 480px), (max-width: 768px) {
  .pageWrapper {
    display: flex;
    flex-direction: column;
    /* Removed large margins that caused scrolling */
  }
  .mainInfo {
    width: 50%;
    padding: 110px; 
  }
  .mainRoutes {
    width: 50%;
    padding: 150px; 
  }
}

/* 
  ===============================================================
   NEW MEDIA QUERIES FOR TYPICAL 15" LAPTOP SIZES (NO SCROLL)
  ===============================================================
   Common widths: 1366px, 1440px, 1536px, etc.
   We reduce font-size and/or padding slightly so text is not cut.
*/

/* For screens around 1280px up to 1439px */
@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .mainInfo {
    padding: 90px; /* Slightly reduced from 110px */
  }
  .mainRoutes {
    padding: 130px; /* Slightly reduced from 150px */
  }
  .mainRoutes a {
    font-size: 9vw; /* Reduced from 11vw to avoid clipping */
  }
}

/* For screens around 1440px up to just before 1400px rule
   (This overlap is unusual but some devices are exactly 1440px wide.) */
@media screen and (min-width: 1440px) and (max-width: 1399px) {
  .mainInfo {
    padding: 100px;
  }
  .mainRoutes {
    padding: 140px;
  }
  .mainRoutes a {
    font-size: 10vw;
  }
}