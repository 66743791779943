.card {
  position: relative;
  width: 300px;
  height: 400px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
  margin: 10px;
}

.card:hover {
  transform: scale(1.1);
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.card:hover .card-overlay {
  opacity: 1;
}

.card-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #1a1818;
  transition: background-color 0.3s;
}

.card-link:hover {
  background-color: #2d3035;
}

@media screen and (min-width: 1400px){
  .card {
    width: 500px;
    height: 700px;
  }
}