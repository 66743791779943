nav a{
    text-decoration: none;
    color: black;
    font-size: 20px;
    margin: 2px;
}

nav a:hover{
    color: #252930;
}

@media screen and (min-width: 1400px){
    nav a{
        font-size: 25px;
    }
}