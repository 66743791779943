.custom-button {
    color: #ece7e1;
    letter-spacing: 1px;
    background-color: #1a1818;
    border-radius: 50px;
    padding: 10px 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 400;
    transition: padding 0.25s ease-in-out;
    border: none;
    cursor: pointer;
  }
  
  .custom-button:hover {
    padding: 15px 55px;
    background-color: #252930;
  }

  @media screen and (min-width: 1400px){
    .custom-button{
      padding: 20px 60px;
      font-size: 22px;
    }

    .custom-button:hover{
      padding: 22px 65px;
    }
  }
  